import React from 'react'
import PropTypes from 'prop-types'

const UserInitials = ({ name, initials, color }) => (
  <span className={`cu-avatar bg--${color}`} data-controller='tooltip' data-tooltip-content-value={name}>
    {initials}
  </span>
)

UserInitials.propTypes = {
  name: PropTypes.string.isRequired,
  initials: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
}

export default UserInitials
