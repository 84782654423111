import { buildUrl } from './url-builders'

const buildAssignee = (
  { name, id },
  { assignee: currentAssignee, ...urlParts }
) => ({
  name,
  selected: (currentAssignee.toString() === id.toString()),
  url: buildUrl({ ...urlParts, assignee: id })
})

const buildAssignees = (
  companyUsers,
  urlParts
) => {
  const assignees = []

  assignees.push(buildAssignee({ name: 'All team members', id: 'all' }, urlParts))
  assignees.push(...companyUsers.map(companyUser => buildAssignee(companyUser, urlParts)))
  assignees.push(buildAssignee({ name: 'Unassigned', id: 'unassigned' }, urlParts))

  return assignees
}

export default buildAssignees
