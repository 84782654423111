import React from 'react'
import PropTypes from 'prop-types'

import formatDate from '../helpers/format-date'
import AddEventButton from './add-event-button'

import Events from './events'
import DayDate from './day-date'

const DayWithEvents = ({
  date,
  events,
  showHeadings,
  addEventPath,
  canViewContact,
  onEventDelete,
  onEventColorChange,
  canManageEvent,
  isFieldStaff,
  eventColors
}) => (
  <ul className='c-card__list schedule-day'>
    {
      showHeadings &&
        (
          <li className='c-card'>
            <div className='flex-grid flex-grid--rows-on-mobile'>
              <div className='flex-grid__cell'>
                <h3>
                  <span data-schedule-day={formatDate(date).toLowerCase()}>
                    <strong><DayDate date={date} /></strong>
                  </span>
                </h3>
              </div>
              {(!isFieldStaff && addEventPath) && (
                <div className='flex-grid__cell--1of6 u-align-right'>
                  <AddEventButton addEventPath={addEventPath} currentDay={date} />
                </div>
              )}
            </div>
          </li>
        )
    }

    <Events
      events={events}
      currentDay={date}
      canViewContact={canViewContact}
      onEventDelete={onEventDelete}
      onEventColorChange={onEventColorChange}
      canManageEvent={canManageEvent}
      isFieldStaff={isFieldStaff}
      eventColors={eventColors}
    />
  </ul>
)

DayWithEvents.defaultProps = {
  showHeadings: true,
  isFieldStaff: false,
  events: [],
  addEventPath: null,
  canViewContact: false,
  canManageEvent: false,
  onEventDelete: () => {},
  onEventColorChange: () => {},
  eventColors: []
}

DayWithEvents.propTypes = {
  showHeadings: PropTypes.bool,
  date: PropTypes.string.isRequired,
  events: PropTypes.array,
  addEventPath: PropTypes.string,
  canViewContact: PropTypes.bool,
  isFieldStaff: PropTypes.bool,
  onEventDelete: PropTypes.func,
  onEventColorChange: PropTypes.func,
  canManageEvent: PropTypes.bool,
  eventColors: PropTypes.arrayOf(PropTypes.string)
}

export default DayWithEvents
