import $ from '@ytb/cash-dom'
import debounce from 'lodash/debounce'

document.addEventListener('app-connect', () => {
  const $newContactFields = () => $('#new_contact_fields')
  const $contactSelectFields = () => $('#contact_select_fields')
  const form = options => options.within.find('input, select').prop('disabled', options.disabled)
  const focusFirstVisibleInput = element => element.find('input[type=text]:enabled').first().trigger('focus')
  const $newContactButton = () => $('#show_new_contact')
  const $newContactHeader = () => $('#show_new_contact_header')
  const $submitFormButton = () => $('button[form]')
  const $selectContactHeader = () => $('#show_select_contact_header')

  $(document).on('click', '#show_new_contact', (event) => {
    event.preventDefault()
    $newContactButton().hide()
    $newContactFields().show()
    $contactSelectFields().hide()
    $newContactHeader().show()
    $selectContactHeader().hide()
    $submitFormButton().removeClass('hidden')
    form({ disabled: false, within: $newContactFields() })
    form({ disabled: true, within: $contactSelectFields() })
    focusFirstVisibleInput($newContactFields())
  })

  $(document).on('change', '#contact_items select', event => {
    const $elm = $(event.target).closest('select')
    if ($elm.val() === 'Other...') {
      const detail = $elm.closest('div.contact_detail')
      detail.find('#other_label').show().trigger('focus').trigger('select')
      $elm.attr('disabled', 'disabled').hide()
      $elm.closest('label').css('padding-top', '0')
    }
  })

  // This event is registered on both the job form and the form_new_paperwork form
  $(document).on('click', '#select_existing_contact', e => {
    $newContactButton().show()
    $contactSelectFields().show()
    $newContactFields().hide()
    $newContactHeader().hide()
    $selectContactHeader().show()
    $submitFormButton().addClass('hidden')
    form({ disabled: false, within: $contactSelectFields() })
    form({ disabled: true, within: $newContactFields() })
    focusFirstVisibleInput($contactSelectFields())
    e.preventDefault()
  })

  function onCustomerSelect (event) {
    const $form = $(event.target).closest('form')
    const customerSelectReset = document.querySelector('.js-customer-select-reset')
    const params = $form.serialize()
    const newUrl = `${window.location.pathname}?${params}`
    window.history.replaceState(null, null, newUrl)
    $('.js-customer-select-results').load(newUrl, params)

    if (event.target.value === '') {
      customerSelectReset.classList.add('js-hidden')
    } else {
      customerSelectReset.classList.remove('js-hidden')
    }
  }

  const customerSelectReset = document.querySelector('.js-customer-select-reset')
  const customerSelect = document.querySelector('.js-customer-select')
  const debouncedCustomerSelect = debounce(onCustomerSelect, 400)

  $(document).on('keyup', '.js-customer-select', event => {
    debouncedCustomerSelect(event)
  })

  $(document).on('click', '.js-customer-select-reset', event => {
    event.preventDefault()
    const $form = $(event.target).closest('form')
    const params = $form.serialize().replace(/q=.*&/, '')
    const newUrl = `${window.location.pathname}?${params}`
    customerSelect.value = ''
    window.history.replaceState(null, null, newUrl)

    $('.js-customer-select-results').load(newUrl, params)
    customerSelectReset.classList.add('js-hidden')
  })

  if (customerSelect) {
    document.addEventListener('app-connect', () => {
      if (customerSelect.value === '') {
        customerSelectReset.classList.add('js-hidden')
      } else {
        customerSelectReset.classList.remove('js-hidden')
      }
    })
  }
})
