import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['assignee', 'selectAllLink', 'deSelectAllLink']

  assign () {
    if (this.allChecked()) {
      this.selectAllLinkTarget.classList.add('hidden')
      this.deSelectAllLinkTarget.classList.remove('hidden')
    }

    if (this.allUnchecked()) {
      this.deSelectAllLinkTarget.classList.add('hidden')
      this.selectAllLinkTarget.classList.remove('hidden')
    }
  }

  selectAll () {
    this.assigneeTargets.forEach(assignee => {
      this.select(assignee)
    })

    this.selectAllLinkTarget.classList.add('hidden')
    this.deSelectAllLinkTarget.classList.remove('hidden')
  }

  deSelectAll () {
    this.assigneeTargets.forEach(assignee => {
      this.deSelect(assignee)
    })

    this.deSelectAllLinkTarget.classList.add('hidden')
    this.selectAllLinkTarget.classList.remove('hidden')
  }

  select (assignee) {
    assignee.querySelector('[data-checkbox]').checked = true
    assignee.querySelector('[data-destroy]').value = false
  }

  deSelect (assignee) {
    assignee.querySelector('[data-checkbox]').checked = false
    assignee.querySelector('[data-destroy]').value = true
  }

  allChecked () {
    return this.assigneeTargets.every(assignee => assignee.querySelector('[data-checkbox]').checked)
  }

  allUnchecked () {
    return this.assigneeTargets.every(assignee => !assignee.querySelector('[data-checkbox]').checked)
  }
}
