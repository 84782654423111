import { Controller } from 'stimulus'
import { Loader } from '@googlemaps/js-api-loader'
import { useClickOutside } from 'stimulus-use'
import debounce from 'lodash/debounce'
import camelCase from 'lodash/camelCase'
import App from '../application/app'

const googleMapsLoader = new Loader({
  apiKey: App.googleMapsApiKey,
  version: 'weekly'
})

export default class extends Controller {
  static targets = [
    'input',
    'postcode',
    'suggestions',
    'suggestionsMenu',
    'suggestionTemplate',
    'suggestion',
    'placesServicePlaceholder'
  ]

  static values = {
    query: { type: String, default: '' },
    suggestions: { type: Array, default: [] }
  }

  // CALLBACKS

  initialize () {
    this.search = debounce(this.search, 500, { leading: true })
  }

  clickOutside () {
    this.suggestionsValue = []
  }

  suggestionsMenuTargetConnected (target) {
    useClickOutside(this, { element: target })
  }

  async placesServicePlaceholderTargetConnected (target) {
    // Do not persist the PlacesService placeholder in the Turbo cache
    target.dataset.turboTemporary = true

    const { PlacesService } = await googleMapsLoader.importLibrary('places')
    this.placesService = new PlacesService(target)
  }

  queryValueChanged (query, _previousQuery) {
    if (query === '' || query.length < 3) {
      this.suggestionsValue = []
      return
    }
    this.search(query)
  }

  suggestionsValueChanged (suggestions, _previousSuggestions) {
    if (!this.hasSuggestionsMenuTarget) { return }
    this.suggestionTargets.forEach(suggestion => suggestion.remove())

    if (suggestions.length === 0) {
      this.suggestionsMenuTarget.classList.remove('menu--open')
    } else {
      this.suggestionsMenuTarget.classList.add('menu--open')
    }

    for (const { text, placeId } of suggestions) {
      const fragment = this.suggestionTemplateTarget.content.cloneNode(true)
      const suggestion = fragment.querySelector('div')
      const link = fragment.querySelector('a')
      link.textContent = text
      link.setAttribute(`data-${this.identifier}-place-id-param`, placeId)

      this.suggestionsTarget.appendChild(suggestion)
    }
  }

  // ACTIONS
  input (event) {
    this.queryValue = event.target.value
  }

  async search (query) {
    if (!query || query === '') { return }

    const { AutocompleteSuggestion } = await googleMapsLoader.importLibrary('places')
    const sessionToken = await this.fetchOrCreateSessionToken()

    const request = { input: query, sessionToken }
    const { suggestions } = await AutocompleteSuggestion.fetchAutocompleteSuggestions(request)

    this.suggestionsValue = suggestions
      .map(suggestion => suggestion.placePrediction)
      .map(placePrediction => ({
        placeId: placePrediction.placeId,
        text: placePrediction.text.toString()
      }))
  }

  choose (event) {
    const { placeId } = event.params
    this.applySuggestion(placeId)
  }

  // PRIVATE

  async applySuggestion (placeId) {
    const place = await this.fetchPlaceDetails(placeId)
    const addressComponents = this.mapAddressComponentsToTypes(place.address_components)
    const { streetNumber, route, locality, postalTown, postalCode, postalCodeSuffix } = addressComponents
    const firstLine = [streetNumber, route].filter(Boolean).join(' ')

    this.inputTarget.value = [firstLine, locality, postalTown].filter(Boolean).join('\n')
    this.postcodeTarget.value = [postalCode, postalCodeSuffix].filter(Boolean).join('-')
    this.suggestionsValue = []
    this.sessionToken = null // A new autocomplete session is needed for each new search
  }

  // PlacesService.getDetails is a callback-based API, so we wrap it in a Promise
  // to make it easier to work with async/await
  async fetchPlaceDetails (placeId) {
    const sessionToken = await this.fetchOrCreateSessionToken()
    return new Promise((resolve, reject) => {
      const request = {
        placeId,
        sessionToken,
        fields: ['address_components'],
        types: ['address']
      }

      this.placesService.getDetails(request, (place, status) => {
        if (status === 'OK') {
          resolve(place)
        } else {
          reject(new Error(`Failed to fetch place details: ${status}`))
        }
      })
    })
  }

  async fetchOrCreateSessionToken () {
    if (this.sessionToken) {
      return this.sessionToken
    }
    const { AutocompleteSessionToken } = await googleMapsLoader.importLibrary('places')
    this.sessionToken = new AutocompleteSessionToken()
    return this.sessionToken
  }

  mapAddressComponentsToTypes (addressComponents) {
    if (!addressComponents) { return [] }

    return addressComponents.reduce((acc, component) => {
      const type = component.types[0]

      acc[camelCase(type)] = component.long_name

      return acc
    }, {})
  }
}
