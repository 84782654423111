import React from 'react'
import PropTypes from 'prop-types'

import AssigneeSelector from './assignee-selector'
import ViewSelector from './view-selector'
import NextPrevious from './next-previous'
import BackToToday from './back-to-today'

const ActionBar = props => {
  const {
    isToday,
    isFieldStaff,
    title,
    companyUsers
  } = props

  return (
    <div>
      {(!isFieldStaff) && (
        <div className='u-mrgn-btm--x3'>

          {(companyUsers.length > 1) && (
            <AssigneeSelector {...props} />
          )}
        </div>
      )}

      <div className='u-float-right u-visible-lrg-scrn-only'>
        <ViewSelector {...props} />
      </div>

      <h2 className='t--lrg c--dark-metal u-mrgn-btm--x3'>
        <NextPrevious {...props} />
        <span className='u-no-print'>&nbsp;&nbsp;&nbsp;</span>
        <span className='u-vertical-middle'>
          <strong>{title}</strong>
          &nbsp;&nbsp;
          {(!isToday) && <BackToToday />}
        </span>
      </h2>
    </div>
  )
}

ActionBar.defaultProps = {
  isToday: false,
  isFieldStaff: false,
  title: '',
  companyUsers: []
}

ActionBar.propTypes = {
  isToday: PropTypes.bool,
  isFieldStaff: PropTypes.bool,
  title: PropTypes.string,
  companyUsers: PropTypes.array
}

export default ActionBar
