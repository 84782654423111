import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const ViewSelector = ({ companyUsers }) => (
  <ul className='pillbox pillbox--sml u-mrgn-l--x3 u-visible-lrg-scrn-only'>
    {(companyUsers.length > 1) && (
      <span>
        <li>
          <a href='/calendar?view=resourceTimelineWeek'>
            <button type='button' className='pillbox__btn '>
              Hourly
            </button>
          </a>
        </li>
        <li>
          <a href='/calendar?view=resourceTimelineMonth'>
            <button type='button' className='pillbox__btn '>
              Daily
            </button>
          </a>
        </li>
      </span>
    )}
    <li>
      <a href='/calendar?view=timeGridWeek'>
        <button type='button' className='pillbox__btn '>
          Week
        </button>
      </a>
    </li>
    <li>
      <a href='/calendar?view=dayGridMonth'>
        <button type='button' className='pillbox__btn '>
          Month
        </button>
      </a>
    </li>
    <li>
      <Link to='/'>
        <button type='button' className='pillbox__btn active'>
          List
        </button>
      </Link>
    </li>
  </ul>
)

ViewSelector.defaultProps = {
  companyUsers: []
}

ViewSelector.propTypes = {
  companyUsers: PropTypes.array
}

export default ViewSelector
