/* eslint no-new: 0 */
import { Controller } from 'stimulus'
import { formatMoney } from 'accounting-js'
import Chart from 'chart.js/auto'

export default class ChartController extends Controller {
  static values = {
    type: String,
    data: Object,
    options: Object,
    unit: String,
    currencyUnit: String
  }

  connect () {
    this.chart = new Chart(
      this.element,
      {
        type: this.typeValue,
        data: this.dataValue,
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: 'bottom',
              ...this.optionsValue.legend
            },
            title: {
              display: false
            },
            tooltip: {
              callbacks: {
                label (context) {
                  if (context.dataset.unit === 'currency') {
                    return `${context.dataset.label}: ${formatMoney(context.raw, { symbol: context.dataset.currencyUnit, precision: 0 })}`
                  }

                  if (context.dataset.label) {
                    return `${context.dataset.label}: ${context.formattedValue}`
                  }

                  return context.formattedValue
                }
              }
            }
          },
          ...this.chartTypeOptions(),
          ...this.optionsValue
        }
      }
    )
  }

  disconnect () {
    this.chart.destroy()
  }

  chartTypeOptions () {
    return {}
  }
}
